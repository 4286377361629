<template>
	<page-header-wrapper>
		<template slot="extra">
			<workbench-tab @change="getTabId" v-model="active"></workbench-tab>
		</template>
		<a-card :bordered="false">
			<div class="table-page-search-wrapper" v-action:query>
				<a-form layout="inline">
					<a-row :gutter="6">
						<a-col :xs="24" :sm="24" :md="12" :lg="12" :xl="8" :xxl="6">
							<a-form-item label="时间筛选：">
								<a-range-picker @change="getDate" v-model="queryParam.time" style="width:220px" />
							</a-form-item>
						</a-col>
						<!-- <a-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6" :xxl="4">
							<a-form-item label="来源">
								<a-select v-model="queryParam.dataSource" placeholder="请选择" default-value="0" style="width:100px">
									<a-select-option value="0">全部</a-select-option>
									<a-select-option value="3">网厅</a-select-option>
									<a-select-option value="2">微信</a-select-option>
								</a-select>
							</a-form-item>
						</a-col> -->
						<a-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6" :xxl="4">
							<a-form-item label="状态">
								<a-select v-model="queryParam.auditStatus" placeholder="请选择" default-value="0" @change="getStatus" style="width:120px">
									<a-select-option :value="index" v-for="(item,index) in statusMap" :key="index">{{item.text}}</a-select-option>
								</a-select>
							</a-form-item>
						</a-col>
						<a-col  :xs="24" :sm="24" :md="13" :lg="13" :xl="9" :xxl="7">
							<a-form-item label="关键词">
								<a-input-group compact>
									<a-select  v-model="queryParam.keyType">
										<a-select-option value="1">
											户名
										</a-select-option>
										<a-select-option value="2">
											户号
										</a-select-option>
									</a-select>
									<a-input style="max-width: 155px;" placeholder="请输入" v-model="queryParam.keyword" />
								</a-input-group>
							</a-form-item>
						</a-col>
						<a-col :xs="24" :sm="24"  :md="4" :lg="4" :xl="3" :xxl="3">
							<span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
								<a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
								<a-button style="margin-left: 8px" @click="reset">重置</a-button>
							</span>
						</a-col>
					</a-row>
				</a-form>
			</div>


			<s-table ref="table" size="default" :rowKey="(record) => record.id" :columns="columns" :data="loadData" showPagination="auto" :scroll="{x:'1500px'}" :pageNum="pageNum">
				<span slot="serial" slot-scope="text, record, index">
					{{ index + 1 }}
				</span>
				<span slot="source" slot-scope="data_source">
					<a-tag :color="getSource(data_source).color">{{getSource(data_source).name}}</a-tag>
				</span>
				<span slot="status" slot-scope="audit_status">
					<a-badge :class="'badge'+audit_status" :status="audit_status | statusTypeFilter" :text="audit_status | statusFilter" />
				</span>
				<span slot="oldName" slot-scope="customer_name">
					{{customer_name || '-'}}
				</span>
				<span slot="oldAcc" slot-scope="customer_id">
					{{customer_id || '-'}}
				</span>
				<span slot="address" slot-scope="customer_address">
					{{customer_address||'-'}}
				</span>
				<span slot="reason" slot-scope="reason">
					<ellipsis :length="35" tooltip class="ellip">
					    {{reason}}
					</ellipsis>
				</span>
				<span slot="time" slot-scope="update_time">
					{{update_time | time}}
				</span>
				<span slot="action" slot-scope="text, record">
					<template>
						<!-- <a @click="handleEdit(record)" v-if="record.audit_status==1" v-action:audit>处理</a>
						<a @click="handleWatch(record)" v-else v-action:view>查看</a>
						<a-divider type="vertical"/>
						<a @click="handlePre(record)" v-action:preview>预览</a> -->
						<template v-if="record.audit_status==1">
							<span v-action:audit class="btn_pass" @click="handleEdit(record,2)">通过</span>
							<span v-action:audit class="btn_reject" @click="handleEdit(record,3)">拒绝</span>
						</template>
						<span v-action:view class="btn_btn" @click="handleWatch(record)">详情</span>
						<!-- <span v-action:preview class="btn_btn" @click="printFile(record)">打印</span> -->
						<a-tooltip v-if="record.audit_status==3">
						    <template slot="title">
								{{record.audit_opinion}}
						    </template>
						    <span class="btn_btn" >拒绝理由</span>
						</a-tooltip>
					</template>
				</span>
			</s-table>

			<!-- 详情 -->
			<a-drawer class="myDrawer" :title="$sys.serviceDetail" width="500px" placement="right"  :visible="visible"
			 @close="onClose">
				<div class="drawer_main">
					<div class="drawer_main_title">{{$sys.basic}}</div>
					<div class="drawer_main_content">
						<div class="drawer_main_item">
							<div class="drawer_main_item_l">{{$sys.accNum}}</div>
							<div class="drawer_main_item_r">{{info&&info.customer_id||'-'}}</div>
						</div>
						<div class="drawer_main_item">
							<div class="drawer_main_item_l">{{$sys.accName}}</div>
							<div class="drawer_main_item_r">{{info&&info.customer_name||'-'}}</div>
						</div>
						<div class="drawer_main_item">
							<div class="drawer_main_item_l">{{$sys.waterAdd}}</div>
							<div class="drawer_main_item_r">{{info&&info.customer_address||'-'}}</div>
						</div>
						<div class="drawer_main_item">
							<div class="drawer_main_item_l">{{$sys.tel}}</div>
							<div class="drawer_main_item_r">{{info&&info.mobile||'-'}}</div>
						</div>
						<div class="drawer_main_item">
							<div class="drawer_main_item_l">{{$sys.reason}}</div>
							<div class="drawer_main_item_r">{{info&&info.reason||'-'}}</div>
						</div>
						<div class="drawer_main_item">
							<div class="drawer_main_item_l">审核状态</div>
							<div class="drawer_main_item_r a_pass" v-if="info&&info.audit_status==2">审核通过</div>
							<div class="drawer_main_item_r a_reject" v-else-if="info&&info.audit_status==3">审核拒绝</div>
							<div class="drawer_main_item_r" v-else>-</div>
						</div>
						<div class="drawer_main_item" v-if="info&&info.audit_status==3">
							<div class="drawer_main_item_l">{{$sys.auditReason}}</div>
							<div class="drawer_main_item_r">{{info&&info.audit_opinion||'-'}}</div>
						</div>
					</div>
					<div class="drawer_main_title">{{$sys.attachment}}</div>
					<div class="drawer_main_content">
						<div v-for="(item,index) in photo" :key="index" v-show="photo.length>0">
							<div class="drawer_main_item">
								<div class="drawer_main_item_l">{{text[index]}}</div>
								<div class="drawer_main_item_r imgList">
									<div  class="imgListContainer">
										<img :src="checkPrefixWithHttp(item[1])?item[1]:prefix+item[1]" preview="1" preview-text="" v-if="item[1]!='null'">
									</div>
								</div>
							</div>
						</div>
						
					</div>
				</div>
				<!-- <div class="drawer_footer" v-show="!isChecked">
					<div class="drawer_footer_right">
						<a-button type="primary" @click="onClose(2)">
							{{$sys.pass}}
						</a-button>
						<a-button type="danger" class="mgl20" @click="onClose(3)">
							{{$sys.reject}}
						</a-button>
					</div>
				</div> -->
			</a-drawer>
			<a-modal class="modal_foot_center" v-model="modalVisible" title="理由" centered @ok="closeModal(1)" @cancel="closeModal(0)">
				<div>
					<a-textarea placeholder="请输入回复内容" :rows="6" v-model="reason" />
				</div>
			</a-modal>
		</a-card>
		<iframe id="frame" :src="iframeUrl" frameborder="0" ref="iframe" style="display:none"></iframe>
	</page-header-wrapper>
</template>

<script>
    import storage from 'store'
	import moment from 'moment'
	import { getUrlKey } from '@/utils'
	import { Modal,message } from 'ant-design-vue'
	import {
		STable,
		Ellipsis
	} from '@/components'
	import {
		waterReconnectionList,
		waterReconnectionInfo,
		handlewaterReconnection
	} from '@/api/service'
	import workbenchTab from './workbenchTab'
	import { prefix,getSource,checkPrefixWithHttp } from '@/utils'

	const columns = [{
			title: '序号',
			scopedSlots: {
				customRender: 'serial'
			},
			width:'90px',
			align:'center'
		},
		{
			title: '数据来源',
			dataIndex:'data_source',
			scopedSlots: {
				customRender: 'source'
			},
			align:'center',
			width:'100px'
		},
		{
			title: '状态',
			dataIndex: 'audit_status',
			scopedSlots: {
				customRender: 'status'
			},
			align:'center',
			width:'120px',
		},
		{
			title: '户号',
			dataIndex: 'customer_id',
			scopedSlots: {
				customRender: 'oldAcc'
			},
			align:'center',
			width:'100px',
		},
		{
			title: '户名',
			dataIndex: 'customer_name',
			scopedSlots: {
				customRender: 'oldName'
			},
			align:'center',
            width:'100px',
            width:'150px',
		},
		{
			title: '装表地址',
			dataIndex: 'customer_address',
			scopedSlots:{
				customRender:'address'
			},
			align:'center',
			width:'200px'
		},
		{
			title: '联系电话',
			dataIndex: 'mobile',
			align:'center',
			width:'145px'
		},
		{
			title: '申请原因',
			dataIndex: 'reason',
			scopedSlots: {
				customRender: 'reason'
			},
			align:'center',
			width:'300px'
		},
		{
			title: '提交时间',
			dataIndex: 'create_time',
			scopedSlots: {
				customRender: 'time'
			},
			align:'center',
			width:'180px'
		},
		{
			title: '操作',
			dataIndex: 'action',
			width: '200px',
			scopedSlots: {
				customRender: 'action'
			},
			align:'center'
		}
	]

	const statusMap = {
		0: {
			status: 'default',
			text: '全部'
		},
		1: {
			status: 'default',
			text: '待审核'
		},
		2: {
			status: 'success',
			text: '审核通过'
		},
		3: {
			status: 'error',
			text: '审核拒绝'
		}
	}

	export default {
		name: 'TableList',
		components: {
			STable,
			Ellipsis,
			workbenchTab
		},
		data() {
			this.columns = columns
			return {
				visible: false,
				advanced: false,
				// 查询参数
				queryParam: {
					time: [],
					auditStatus: '0',
					keyword: '',
					startTime: '',
					endTime: '',
					worker: '',
					type:4,   
					keyType:'1',
					dataSource:'0'
				},
				loadData: parameter => {
					const requestParameters = Object.assign({}, parameter, this.queryParam)
					return waterReconnectionList(requestParameters)
						.then(res => {
							return res.data
						})
				},
				selectedRowKeys: [],
				selectedRows: [],
				active: 0,
				photo: [],
				modalVisible: false, //回复弹窗
				reason: '', //审核理由
				statusMap: statusMap,
				info: '',   //详情
				id: '',     //列表id
				status:'',  //2 通过  3 拒绝
				isChecked:false,
				prefix:prefix,
				text:['营业执照'],
				customerId:'',   //户号
				pageNum:0,
				iframeUrl:'',
			}
		},
		filters: {
			time(t) {
				if (t) {
					return moment(parseInt(t) * 1000).format('YYYY/MM/DD HH:mm:ss')
				} else {
					return t
				}
			},
			statusFilter(type) {
				return statusMap[type].text
			},
			statusTypeFilter(type) {
				return statusMap[type].status
			}
		},
		created(){
			this.pageNum = Number(getUrlKey('p')||0)
		},
		computed: {
			rowSelection() {
				return {
					selectedRowKeys: this.selectedRowKeys,
					onChange: this.onSelectChange
				}
			}
		},
		methods: {
			checkPrefixWithHttp:checkPrefixWithHttp,
			getSource:getSource,
			printFile(item){//点击打印
				let url = process.env.VUE_APP_PRINT_URL+'/h5/waterReconnectionJMH.html?id=' + item.id+'&token='+storage.get('Access-Token')+'&v='+Date.parse(new Date());
				this.iframeUrl = process.env.VUE_APP_PRINT_URL+'/h5/waterReconnectionJMH.html?id=';
				this.$nextTick(_=>{
					this.iframeUrl = url;
				})
				setTimeout(_=>{
					var int = null;
					var count = 0;
					let img = null;
					try{
						img = document.getElementById('frame').contentWindow.document.getElementById('img0')
					}catch(err){
						img = null;
					}
					if(img){
						let iframe = document.getElementById('frame');
						iframe.contentWindow.print();
						img = null
					}else{
						int = setInterval(_=>{
							try{
								img = document.getElementById('frame').contentWindow.document.getElementById('img0')||null;
								if(img){
									clearInterval(int);
									let iframe = document.getElementById('frame');
									iframe.contentWindow.print();
									img = null
								}else{
									count++;
									if(count>=10){
										clearInterval(int);
										img = null
									}
								}
							}catch(err){
								clearInterval(int);
								img = null
							}
						},500)
					}
				},1000)
			},
			reply() { //回复弹窗打开
				this.modalVisible = true
			},
			closeModal(status) { //回复弹窗关闭 1确定 2取消
				//console.log('status',status)
				let that = this
				let reason = this.reason
				let params = {
					auditOpinion: reason,
					id: this.id,
					auditStatus:3,
					customerId:this.customerId,
					type:4
				}
				if(status==1){
					if(!reason){
						this.$message.error('请输入拒绝理由')
						return false
					}
					Modal.confirm({
						content:'确认拒绝吗?',
						onOk(){
							handlewaterReconnection(params).then(res => {
								if(res.code==200){
									message.success('处理成功')
									that.visible = false
									that.$refs.table.refresh()
									that.reason = ''
									that.id = ''
									that.status=''
									that.customerId = ''
									that.modalVisible = false
								}else{
									message.error(res.msg)
								}
							})
						},
						onCancel(){
							that.modalVisible = false
						}
					})
				}
			},
			getTabId(e) {
				if (e == 0) {
					this.queryParam.worker = ''
				} else if (e == 1) {
					this.queryParam.worker = 'A'
				} else if (e == 2) {
					this.queryParam.worker = 'B'
				}
				this.$refs.table.refresh(true)
			},
			getDate(date, dateString) { //时间筛选
				this.queryParam.time = dateString
				this.queryParam.startTime = this.queryParam.time[0] ? this.queryParam.time[0]+' 00:00:00':''
				this.queryParam.endTime = this.queryParam.time[1] ? this.queryParam.time[1]+' 23:59:59':''
			},
			getStatus(e) {
				this.auditStatus = e
			},
			reset() { //重置
				this.queryParam.time = null
				this.queryParam.startTime = ''
				this.queryParam.endTime = ''
				this.queryParam.auditStatus = '0'
				this.queryParam.keyword = ''
				this.queryParam.keyType = '1'
				this.queryParam.dataSource='0'
				this.$refs.table.refresh(true)
			},
			handleEdit(record,status) { //处理
				this.id = record.id;
				this.customerId = record.customer_id;
				this.onClose(status);
			},
			handleWatch(record){
				this.id = record.id
				this.getDetail(record.id)
				this.isChecked = true
				this.visible = true
			},
			handlePre(record) { //预览
				let url = this.prefix+'/public/h5/waterReconnectionJMH.html?id=' + record.id+'&token='+localStorage.getItem('Access-Token')
				window.open(url)
			},
			getDetail(id) { //详情
				let params = {
					id: id,
					type:4
				}
				waterReconnectionInfo(params).then(res => {
					//console.log('res',res)
					if(res.code==200){
						this.info = res.data
						this.info = res.data
						var arr = Object.entries(res.data.attachment)
						this.photo = arr;
						this.$previewRefresh()
					}else{
						message.error(res.msg)
					}
				})
			},
			onClose(status) { //关闭右侧弹出层   2 通过  3 拒绝
				//console.log('status', status)
				if (status == 2) {
					let that = this
					let reason = this.reason
					let params = {
						auditOpinion: reason,
						id: this.id,
						auditStatus:2,
						customerId:this.customerId,
						type:4
					}
					Modal.confirm({
						content:'确认通过吗?',
						onOk(){
							handlewaterReconnection(params).then(res => {
								if(res.code==200){
									message.success('处理成功')
									that.visible = false
									that.$refs.table.refresh()
									that.reason = ''
									that.id = ''
									that.status=''
									that.customerId = ''
									that.modalVisible = false
								}else{
									message.error(res.msg)
								}
							})
						},
						onCancel(){
							that.modalVisible = false
						}
					})
				} else if(status==3){
					this.modalVisible = true
				}else {
					this.visible = false
				}
			}
		}
	}
</script>
